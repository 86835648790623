import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Container,
  Divider,
  Input,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { grey, blueGrey } from '@mui/material/colors';
import * as Yup from 'yup';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { AuthQueryName, SIGN_UP_MUTATION } from '../../gql';
import {
  MIN_PASSWORD_LENGTH,
  emailRegex,
  useYupValidationResolver,
} from '../../helpers';
import { LogoIcon } from '../../components';

const containerStyles = { height: '100%', display: 'flex' };
const rootStyles = { width: '100%', height: '100%', background: blueGrey[900] };
const stackStyles = {
  boxShadow: `0 8px 24px ${grey[800]}`,
  width: '100%',
  background: 'white',
  borderRadius: 2,
};
const submitButtonStyles = {
  borderRadius: 2,
  fontSize: '1rem',
};
const logoStyles = { fontSize: 64 };

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('E-mail is mandatory field.')
    .matches(emailRegex, 'Please, provide a valid e-mail.'),
  password: Yup.string()
    .required('Password is mandatory field.')
    .min(
      MIN_PASSWORD_LENGTH,
      `Password must be at least ${MIN_PASSWORD_LENGTH} characters.`
    ),
  confirmPassword: Yup.string()
    .required('Please, confirm password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match.'),
});

const SignUp = React.memo(() => {
  const [errors, setErrors] = useState<Array<{ error: string }>>([]);
  const resolver = useYupValidationResolver(validationSchema, setErrors);
  const { register, handleSubmit } = useForm({ resolver });

  const navigate = useNavigate();

  const [signUp] = useMutation(SIGN_UP_MUTATION, {
    refetchQueries: [AuthQueryName],
    onCompleted: ({ signUp: { __typename, ...data } }) => {
      // console.log(data);
    },
    // onError:
  });

  const onSubmit = async (values: { email: string; password: string }) => {
    const { data } = await signUp({
      variables: {
        email: values.email,
        password: values.password,
      },
    });

    if (data && !data.signUp.errors) {
      navigate('/');
    } else {
      setErrors(data!.signUp!.errors);
    }
  };

  return (
    <div style={rootStyles}>
      <Helmet title="Sign-in"></Helmet>
      <Container maxWidth="xs" sx={containerStyles}>
        <Stack
          spacing={2}
          alignItems="flex-start"
          justifyContent="center"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={stackStyles}
          px={4}
          pt={8}
          pb={12}
          mt={18}
          mb="auto"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            width="100%"
          >
            <LogoIcon sx={logoStyles} />
            <Typography textAlign="center" fontWeight="bold" variant="h2">
              Acolyte
            </Typography>
          </Stack>
          <Stack width="100%">
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <Input
              fullWidth
              id="email"
              type="text"
              placeholder="e-mail"
              {...register('email', {
                setValueAs: (value) => value.toLowerCase(),
              })}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              fullWidth
              id="password"
              type="password"
              {...register('password')}
            />
          </Stack>
          <Stack width="100%">
            <InputLabel htmlFor="confirmPassword">Confirm password</InputLabel>
            <Input
              fullWidth
              id="confirmPassword"
              type="password"
              {...register('confirmPassword')}
            />
          </Stack>
          <Divider color="transparent" />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={submitButtonStyles}
          >
            Sign in
          </Button>
          <Divider />
          <Stack width="100%">
            {errors.length > 0 && (
              <Stack>
                {errors.map((item: { error: string }) => (
                  <Typography color="red">{item.error}</Typography>
                ))}
              </Stack>
            )}
          </Stack>
          <Stack width="100%" alignItems="center">
            <Stack direction="row" justifyContent="center" width="100%">
              <Typography variant="caption">
                Already have an account?{' '}
                <Link to="/signin" style={{ color: '#FF0070' }}>
                  Sign-in
                </Link>
              </Typography>
            </Stack>
            <Typography variant="caption">
              <Link to="/" style={{ color: '#D76B90' }}>
                Home
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
});

export { SignUp };

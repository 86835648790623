import { useState } from 'react';

const useSiteFindsFilters = () => {
  const [columnFilters, setColumnFilters] = useState([]);

  return {
    columnFilters,
    setColumnFilters,
  };
};

export { useSiteFindsFilters };

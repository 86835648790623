import React from 'react';
import Helmet from 'react-helmet';
import { Container, Stack, Typography } from '@mui/material';

import { Header } from '../../components';

const Activity = () => {

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Acolyte"></Helmet>
      <Header />
      <Container maxWidth="xl">
        <Stack gap={2}>
          <Typography fontWeight="bold" variant="h4" gutterBottom>
            Activity log
          </Typography>
        </Stack>
      </Container>
    </div>
  );
};
export { Activity };
